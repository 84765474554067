import { useCandidateStore } from "@/store/candidate";
import { useApplicationStore } from "@/store/application";

export default defineNuxtRouteMiddleware(async (to, _) => {
  const { showProfile, selectedCandidateId } = storeToRefs(useCandidateStore());
  const { selectedApplication } = storeToRefs(useApplicationStore());
  if (showProfile.value) {
    showProfile.value = false;
    selectedCandidateId.value = null;
  }
  if (selectedApplication.value) selectedApplication.value = null;
});
